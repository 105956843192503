import { z } from "zod"

export const RTCSessionDescriptionInitSchema = z.object({
  sdp: z.string().optional(),
  type: z.enum(["answer", "offer", "pranswer", "rollback"]),
})
export type RTCSessionDescriptionInit = z.infer<typeof RTCSessionDescriptionInitSchema>

export const ClientOfferSchema = z.object({
  kind: z.literal("offer"),
  offer: RTCSessionDescriptionInitSchema,
  targetId: z.string(),
})
export type ClientOffer = z.infer<typeof ClientOfferSchema>

export const ClientAnswerSchema = z.object({
  kind: z.literal("answer"),
  answer: RTCSessionDescriptionInitSchema,
  targetId: z.string(),
})
export type ClientAnswer = z.infer<typeof ClientAnswerSchema>

export const ICECandidateSchema = z.object({
  candidate: z.string().optional(),
  sdpMLineIndex: z.number().nullish(),
  sdpMid: z.string().nullish(),
  usernameFragment: z.string().nullish(),
})
export type ICECandidate = z.infer<typeof ICECandidateSchema>

export const ClientICECandidateSchema = z.object({
  kind: z.literal("icecandidate"),
  candidate: ICECandidateSchema,
  targetId: z.string(),
})
export type ClientICECandidate = z.infer<typeof ClientICECandidateSchema>

export const ClientTextSchema = z.object({
  kind: z.literal("text"),
  text: z.string().min(1),
})
export type ClientText = z.infer<typeof ClientTextSchema>

export const ClientExchangeICECandidatesSchema = z.object({
  kind: z.literal("exchange-ice"),
  targetId: z.string(),
})
export type ClientExchangeICECandidates = z.infer<typeof ClientExchangeICECandidatesSchema>

export const ClientMessageSchema = z.discriminatedUnion("kind", [
  ClientOfferSchema,
  ClientAnswerSchema,
  ClientICECandidateSchema,
  ClientTextSchema,
  ClientExchangeICECandidatesSchema,
])
export type ClientMessage = z.infer<typeof ClientMessageSchema>

const ServerOfferSchema = z.object({
  kind: z.literal("offer"),
  offer: RTCSessionDescriptionInitSchema,
  originId: z.string(),
})
export type ServerOffer = z.infer<typeof ServerOfferSchema>

const ServerAnswerSchema = z.object({
  kind: z.literal("answer"),
  answer: RTCSessionDescriptionInitSchema,
  originId: z.string(),
})
export type ServerAnswer = z.infer<typeof ServerAnswerSchema>

const ServerICECandidateSchema = z.object({
  kind: z.literal("icecandidate"),
  candidate: ICECandidateSchema,
  originId: z.string(),
})
export type ServerICECandidate = z.infer<typeof ServerICECandidateSchema>

const ServerTextSchema = z.object({
  kind: z.literal("text"),
  text: z.string(),
  clientId: z.string(),
})
export type ServerText = z.infer<typeof ServerTextSchema>

const ServerPlayerConnectSchema = z.object({
  kind: z.literal("player-connect"),
  connectedId: z.string(),
  players: z.array(z.string()),
})
export type ServerPlayerConnect = z.infer<typeof ServerPlayerConnectSchema>

const ServerPlayerDisconnectSchema = z.object({
  kind: z.literal("player-disconnect"),
  disconnectedId: z.string(),
  players: z.array(z.string()),
})
export type ServerPlayerDisconnect = z.infer<typeof ServerPlayerDisconnectSchema>

export const ServerMessageSchema = z.discriminatedUnion("kind", [
  ServerOfferSchema,
  ServerAnswerSchema,
  ServerICECandidateSchema,
  ServerTextSchema,
  ServerPlayerConnectSchema,
  ServerPlayerDisconnectSchema,
])
export type ServerMessage = z.infer<typeof ServerMessageSchema>
