// This file is just for demo purposes.
// It shows a page that can interact with
// the backend ws server.

import PartySocket from "partysocket"
import { ZodError } from "zod"
import * as Schemas from "./schemas"

declare const PARTYKIT_HOST: string

const STUN = "stun:stun.l.google.com:19302"
const $ = document
const websocketContainer = $.getElementById("websocket-container") as HTMLDivElement
const webrtcContainer = $.getElementById("webrtc-container") as HTMLDivElement
const roomInput = $.getElementById("room") as HTMLInputElement
const playerInput = $.getElementById("player") as HTMLInputElement
const websocketTemplate = $.getElementById("send-message-template") as HTMLTemplateElement
const webrtcTemplate = $.getElementById("webrtc-peer-template") as HTMLTemplateElement

const searchParams = new URLSearchParams(window.location.search)
const room = searchParams.get("room")
const player = searchParams.get("player")

const rtcPeers = new Map<string, RTCPeerConnection>()
let party: PartySocket | null = null

if (room && player) {
  connectPlayerToRoom(player, room)
  roomInput.value = room
  playerInput.value = player
}

function connectPlayerToRoom(player: string, room: string) {
  party = new PartySocket({
    host: PARTYKIT_HOST,
    room,
    id: player,
  })

  party.addEventListener("message", (event) => {
    handleMessage(event.data)
  })

  party.addEventListener("open", () => {
    addRoomElements()

    const form = $.getElementById("send-message-form") as HTMLFormElement
    const messageInput = $.getElementById("message") as HTMLInputElement
    form.addEventListener("submit", (ev) => {
      ev.preventDefault()
      send({
        kind: "text",
        text: messageInput.value,
      })
      messageInput.value = ""
    })
  })
}

function addRoomElements() {
  const form = websocketTemplate.content.cloneNode(true)
  websocketContainer.appendChild(form)
}

async function handleOfferMessage(message: Schemas.ServerOffer) {
  console.log(`New offer incoming from ${message.originId}`)
  if (message.originId === party!.id) {
    console.log("Skipping offer, originated from self")
    return
  }

  const peer = rtcPeers.get(message.originId)
  if (!peer) {
    console.warn("Failed to handle offer message - no peer for this id")
    return
  }
  try {
    await peer.setRemoteDescription(message.offer)
    const answer = await peer.createAnswer()
    await peer.setLocalDescription(answer)
    console.log("Sending answer to", message.originId)
    send({
      kind: "answer",
      answer,
      targetId: message.originId,
    })
  } catch (err) {
    console.error("Error creating answer", err)
  }
}

async function createOffer(peer: RTCPeerConnection, targetId: string) {
  try {
    const offer = await peer.createOffer()
    await peer.setLocalDescription(offer)
    console.log("Sending offer for player: ", targetId)
    send({
      kind: "offer",
      offer,
      targetId,
    })
  } catch (err) {
    console.error("Error creating offer", err)
  }
}

async function createPeer(id: string) {
  console.log("Creating RTC peer for player: ", id)
  const peer = new RTCPeerConnection({ iceServers: [{ urls: STUN }] })
  rtcPeers.set(id, peer)

  peer.onicecandidateerror = (event) => {
    console.error("ICE Candidate Error:", event)
  }

  peer.onsignalingstatechange = () => {
    console.log("Signaling State:", peer.signalingState)
  }

  peer.ondatachannel = (ev) => {
    setupChannel(ev.channel, id)
  }

  // Only 1 side of the connection needs to
  // initiate by sending out the first offer.
  const shouldInitiateOffer = id.localeCompare(party!.id) > 0
  if (shouldInitiateOffer) {
    console.log("You ARE initiating the offer to the peer:", id)
    const channel = peer.createDataChannel("VoidChannel")
    setupChannel(channel, id)
    await createOffer(peer, id)
  } else {
    console.log("You are NOT initiating the offer to the peer:", id)
  }

  peer.onicecandidate = (event) => {
    if (event.candidate) {
      console.log("Sending ICE candidate ", event.candidate.candidate)
      send({
        kind: "icecandidate",
        candidate: event.candidate.toJSON(),
        targetId: id,
      })
    }
  }
}

function addWebRtcElement(id: string, channel: RTCDataChannel) {
  const div = webrtcTemplate.content.cloneNode(true)
  const container = $.createElement("div")
  container.setAttribute("id", `peer-id-${id}`)
  container.appendChild(div)
  const el = webrtcContainer.appendChild(container)
  const title = el.querySelector<HTMLSpanElement>(".webrtc-peer-name")
  if (title) {
    title.textContent = id
  }
  const form = el.querySelector<HTMLFormElement>(".webrtc-peer-form")
  form?.addEventListener("submit", (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target as HTMLFormElement)
    channel.send(`${party!.id} -> ${formData.get("message")}`)
    form.reset()
  })
  return el
}

function setupChannel(channel: RTCDataChannel, id: string) {
  channel.onopen = (_ev) => {
    console.log("Data channel is open!")
    addWebRtcElement(id, channel)
  }

  channel.onmessage = (ev) => {
    const container = $.getElementById(`peer-id-${id}`)
    const list = container?.querySelector(".webrtc-messages-list")
    if (list) {
      const item = $.createElement("div")
      item.textContent = ev.data
      list.append(item)
    }
    console.log("🎉 Received message!!:", ev.data)
  }

  channel.onclose = () => {
    $.getElementById(`peer-id-${id}`)?.remove()
  }

  channel.onerror = (error) => {
    console.error("Data Channel Error:", error)
  }
}

function handleAnswerMessage(message: Schemas.ServerAnswer) {
  console.log("Answer incoming from:", message.originId)
  if (message.originId === party?.id) {
    console.warn("Skipping setting answer for self")
    return
  }

  const peer = rtcPeers.get(message.originId)
  if (!peer) {
    console.warn("RTCPeerConnection is not initialized for: ", message.originId)
    return
  }
  peer.setRemoteDescription(message.answer)
}

function handleTextMessage(message: Schemas.ServerText) {
  console.log("new text message coming in")
  websocketContainer.appendChild($.createTextNode(`${message.clientId} -> ${message.text}`))
  websocketContainer.appendChild($.createElement("br"))
}

async function handleICECandidateMessage(message: Schemas.ServerICECandidate) {
  const peer = rtcPeers.get(message.originId)
  if (!peer) {
    console.warn("Failed to add ICE candidate - no peer for ths id")
    return
  }

  await peer.addIceCandidate(message.candidate)
}

function handlePlayerConnectMessage(message: Schemas.ServerPlayerConnect) {
  console.log(`There are ${message.players.length} players in this room, including you`)
  for (const player of message.players) {
    if (player !== party!.id && !rtcPeers.has(player)) {
      createPeer(player)
    }
  }
}

function handlePlayerDisconnectMessage(message: Schemas.ServerPlayerDisconnect) {
  console.log(`There are ${message.players.length} players in this room, including you`)
  rtcPeers.get(message.disconnectedId)?.close()
  rtcPeers.delete(message.disconnectedId)
}

function handleMessage(message: string) {
  try {
    const json = JSON.parse(message)
    const parsed = Schemas.ServerMessageSchema.parse(json)
    console.log(`New message incoming of type: ${parsed.kind}`)
    switch (parsed.kind) {
      case "player-connect":
        return handlePlayerConnectMessage(parsed)
      case "player-disconnect":
        return handlePlayerDisconnectMessage(parsed)
      case "offer":
        return handleOfferMessage(parsed)
      case "icecandidate":
        return handleICECandidateMessage(parsed)
      case "answer":
        return handleAnswerMessage(parsed)
      case "text":
        return handleTextMessage(parsed)
    }
  } catch (err) {
    if (err instanceof ZodError) {
      console.log("Zod validation error for message ", message, err)
    } else {
      console.log("Unable to parse message as JSON ", message, err)
    }
  }
}

function send(message: Schemas.ClientMessage) {
  if (party) {
    party.send(JSON.stringify(message))
  } else {
    console.warn("Party is not initialized")
  }
}
